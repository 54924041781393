.table-container {
    width: 50%;
    max-width: 100%; /* Ensures it doesn't overflow the viewport */
    overflow: hidden; /* Hide the overflow */
    resize: both; /* Allow resizing */
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align children like buttons in the center */
    margin: auto; /* Center the container */
    position: relative; /* Needed for positioning children absolutely if needed */
}

.filter-label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.form-group {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.top-controls {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 20px;
}



.scroll-sync {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 500px;
}

.results-table th, .results-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.results-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 10;
}

.results-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.results-table tr:hover {
    background-color: #f1f1f1;
}
button {
    padding: 10px 10px;   /* Top and bottom padding of 10px, left and right padding of 10px */
    font-size: 14px;      /* Larger text inside the button */
    min-width: 100px;     /* Minimum width of 100px */
    height: 48px;         /* Fixed height of 48px */
    cursor: pointer;      /* Changes the cursor to a pointer when hovering over the button */
    border: 1px solid #ccc;  /* Lighter and thinner border */
    border-radius: 5px;   /* Rounded corners */
}

.button-container {
    width: auto; /* Only as wide as its content */
    padding: 10px;
}
input[type="text"], select {
    padding: 5px;  
}
.content {
    padding-top:70px;
}

.header-bar {
    width: 100%;
    height: auto;
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    flex-wrap: wrap;  /* Allow items to wrap to the next line */
}

.header-left {
    display: flex;
    align-items: center;
}

.header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;  /* Align items to the end of the right section */
    margin-right: 40px;
    font-size: 14px;
    }

.logout-button {
    padding: 5px 10px;
    background-color: darkviolet;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 24px;
    margin-top: 5px;  /* Add some margin between the welcome text and the button */
}

.logout-button:hover {
    background-color: #333;
}
